<template>
    <div class="table-box">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
    </div>
</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                opt: {
                    search: [],
                    columns: [
                        {
                            label: "人脸名称",
                            key: "name",
                        },
                        {
                            label: "人脸类型",
                            key: "faceTypeStr"
                        },
                        {
                            label: "有效期",
                            key: "faceTimeEffectStr",
                        },
                        {
                            label: "添加时间",
                            key: "createTime"
                        },
                    ]
                },
                userInfo: {}
            };
        },
        created() {
            this.userInfo = this.cache.getLS("userInfo");
            this.row = this.cache.get("lockInfo");
        },
        activated() {
            this.row = this.cache.get("lockInfo");
        },
        mounted() {

        },
        methods: {
            onGet(opt) {
                let dto = {
                current: opt.skip,
                pageSize: opt.limit,
                deviceId: this.$route.params.deviceId,
                keyType: 6
                };
                this.post("homelock-service/user-device-key/web/api/v1/record", dto, {
                    isUseResponse: true
                }).then(res => {
                    if (res.data.data) {
                        for (let i = 0; i < res.data.data.length; i++) {
                            let item = res.data.data[i]; 
                            if (item.validTimes) {
                                if(item.validTimes == -1) {
                                    if(item.beginTime && item.endTime){
                                        item.faceTypeStr = '限时'
                                        item.faceTimeEffectStr = this.timestampToTime(item.beginTime)+' ~ '+this.timestampToTime(item.endTime)
                                    }else{
                                        item.faceTypeStr = '永久'
                                        item.faceTimeEffectStr = '--' 
                                    }
                                }else{
                                    item.faceTypeStr = '单次'
                                    item.faceTimeEffectStr = '--'
                                }
                            }
                        }
                    }else {
                        res.data.data = []
                    }
                    opt.cb(res.data);
                });
            },
            timestampToTime(timestamp) {
                var date = new Date(timestamp*1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
                var YY = date.getFullYear() + '-';
                var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                var DD = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
                var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return YY+MM+DD+hh+mm+ss;
            }
        }
    };
</script>
<style lang="scss">
    .table-box {
        height: 620px;
    }
</style>