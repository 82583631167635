<template>
    <div class="table-box">
          <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
    </div>
  </template>
  <script>
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          search: [],
          columns: [
            { label: "编码", key: "id" },
            { label: "MAC地址", key: "deviceMac" },
            { label: "发钥匙时间", key: "createTime" },
            { label: "发钥匙人", key: "userName" },
            { label: "使用人", key: "name" },
            // { label: "设备端软件版本", key: "firmwareVersion" },
          ],
        },
        roomList: [],
      };
    },
    created() {
      console.log("personKeyInfo created!!");
      
    },
    activated() {
      console.log("personKeyInfo activated!!");
    },
    methods: {
      onGet(opt) {
        let dto = {
          current: opt.skip,
          pageSize: opt.limit,
          deviceId: this.$route.params.deviceId,
          keyType: 3
           };
        this.post("homelock-service/user-device-key/web/api/v1/record", dto, {
          isUseResponse: true
        }).then(res => {
          if (res.data.data) {
            for (let i = 0; i < res.data.data.length; i++) {
                let item = res.data.data[i]; 
                item.userName = item.userName ? item.userName : '--'
                item.name = item.name ? item.name : '--'
            }
          }else {
            res.data.data = []
          }
          opt.cb(res.data);
        });
      },
    }
  };
  </script>
  <style lang="scss">
  .table-box{
    height: 620px;
  }
  </style>