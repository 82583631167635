<template>
    <div class="lock-log">
      <div class="title">
        <app-header :title="title" :isShowBack="true"></app-header>
      </div>
      <ul class="tab-ul">
        <li :class="{ 'ative': currentTab == 'personInfo' }" @click="toggleTab('personInfo')">门锁详情</li>
        <li :class="{ 'ative': currentTab == 'personOpenLockInfo' }" @click="toggleTab('personOpenLockInfo')">开锁人信息</li>
        <li :class="{ 'ative': currentTab == 'personKeyInfo' }" @click="toggleTab('personKeyInfo')">钥匙信息</li>
        <li v-show="row.model == 'WF-X11MG' || row.model == 'WF-S64G' || row.model == 'WF-X3M' || row.model == 'WF-X4M' || row.model == 'WF-S53' || row.model == 'WF-S65'|| row.model == 'WF-S54'" :class="{ 'ative': currentTab == 'x11mPasswordInfo' }" @click="toggleTab('x11mPasswordInfo')">密码信息</li>
        <li v-show="row.model == 'WF-X11MG' || row.model == 'WF-S64G' || row.model == 'WF-C1Z' || row.model == 'WF-X3M' || row.model == 'WF-X4M' || row.model == 'WF-S53' || row.model == 'WF-S65'|| row.model == 'WF-S54'" :class="{ 'ative': currentTab == 'x11mFingerInfo' }" @click="toggleTab('x11mFingerInfo')">指纹信息</li>
        <li v-show="row.model == 'WF-S65'" :class="{ 'ative': currentTab == 's65FaceInfo' }" @click="toggleTab('s65FaceInfo')">人脸信息</li>
        <li v-show="row.model == 'WF-S64G' || row.model == 'WF-S53' || row.model == 'WF-S65'|| row.model == 'WF-S54'" :class="{ 'ative': currentTab == 's64SendCardInfo' }" @click="toggleTab('s64SendCardInfo')">发卡信息</li>
        <li :class="{ 'ative': currentTab == 'personDoorOpen' }" @click="toggleTab('personDoorOpen')">开锁日志</li>
        
      </ul>
      <keep-alive>
        <personDoorOpen :is="currentTab"></personDoorOpen>
      </keep-alive>
  
    </div>
  </template>
  <script>
  import personInfo from './personInfo';
  import personDoorOpen from './personDoorOpen';
  import personOpenLockInfo from './personOpenLockInfo';
  import personKeyInfo from './personKeyInfo';
  import x11mPasswordInfo from './x11mPasswordInfo';
  import x11mFingerInfo from './x11mFingerInfo';
  import s64SendCardInfo from './s64SendCardInfo';
  import s65FaceInfo from './s65FaceInfo';
  export default {
    components: {
      personInfo,
      personDoorOpen,
      personOpenLockInfo,
      personKeyInfo,
      x11mPasswordInfo,
      x11mFingerInfo,
      s64SendCardInfo,
      s65FaceInfo
    },
    data() {
      let _this = this;
      return {
        currentTab: 'personInfo',
        title: "查看详情",
        row: "",
      };
    },
    activated() {
      this.row = this.$route.params;
      this.currentTab = 'personInfo';
    },
    mounted() {
      this.currentTab = 'personInfo';
    },
    methods: {
      toggleTab: function (tab) {
        this.currentTab = tab; // tab 为当前触发标签页的组件名
      },
    }
  };
  </script>
  <style lang="scss">
  .lock-log {
    .danger {
      color: #f56c6c;
    }
  
    .titleSpans {
      padding-bottom: 20px;
    }
  
    .titleSpan {
      color: #606266;
    }
  
    .info1 {
      padding: 10px;
      margin-bottom: 20px;
      font-size: 14px;
      color: #606266;
  
      .txt {
        margin-right: 20px;
      }
    }
  
    .info {
      padding: 10px;
      margin-bottom: 20px;
      font-size: 14px;
      color: #606266;
      background-color: #e4e7ed;
  
      .txt {
        margin-right: 20px;
      }
    }
  
    // tab样式
    .tab-ul {
      list-style: none;
      padding-left: 0;
      margin-top: 0;
      height: 42px;
      box-sizing: border-box;
      margin-bottom: 40px;
      width: 100%;
      border-bottom: 2px solid #e4e7ed;
  
      li {
        cursor: pointer;
        padding-left: 18px;
        padding-right: 18px;
        height: 42px;
        line-height: 42px;
        float: left;
        text-align: center;
        margin-right: 13px;
        font-size: 15px;
        background-color: #ffffff;
        color: #303133;
        box-sizing: border-box;
        border-bottom: 2px solid #e4e7ed;
  
        &.ative {
          // color: #ffffff;
          // background: #5e74f1;
          color: #30BAC1;
          border-bottom: 2px solid #30BAC1;
        }
      }
  
      &::after {
        content: "";
        display: block;
        clear: both;
      }
    }
  
    .landlord-manage-box .el-tab-pane {
      height: 650px;
    }
  }
  </style>
  