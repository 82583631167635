<template>
    <div class="table-box">
      <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
    </div>
</template>
  <script>
    export default {
      data() {
        let _this = this;
        return {
          opt: {
            columns: [
              {
                label: "开锁人姓名",
                key: "userName"
              },
              {
                label: "电话号码",
                key: "userPhone"
              },
              {
                label: "角色",
                key: "roleName"
              },
              {
                label: "最近一次开锁时间",
                key: "lastActionTime"
              },
               {
                label: "添加时间",
                key: "createTime"
              },
            ]
          },
        };
      },
      created() {
        console.log("personOpenLockInfo created!!");
  
      },
      activated() {
        console.log("personOpenLockInfo activated!!");
      },
      mounted() {
  
      },
      methods: {
        onGet(opt) {
            let dto = {
                current: opt.skip,
                pageSize: opt.limit,
                category: 'locker',
                deviceId: this.$route.params.deviceId,
            };
            this.post("/homelock-service/user-device/web/api/v1/unlock-info", dto, {
                isUseResponse: true
            }).then(res => {
                if (res.data.data) {
                    for (let i = 0; i < res.data.data.length; i++) {
                        let item = res.data.data[i];
                        item.userPhone = item.userPhone ? item.userPhone : '--';
                        item.roleName = item.grantType ? ["管理员","普通用户"][item.grantType-1] : '--';
                        item.userName = item.userName ? item.userName : '--';
                        item.lastActionTime = item.lastActionTime ? item.lastActionTime : '--';
                    }
                } else {
                    res.data.data = []
                }
                opt.cb(res.data);
            });
        },
      }
    };
  </script>
  <style lang="scss" scoped>
    .table-box {
      height: 620px;
    }
  
  
  .content {
    padding-left: 39px;
  }
    .content-row {
      padding-bottom: 24px;
    }
  
    .failureDetail {
      display: flex;
    }
  
    .dialog-footer {
      text-align: center;
    }
  </style>