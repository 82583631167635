<template>
    <div class="table-box">
      <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
      <el-dialog title="查看失败原因" :visible.sync="dialogVisible" width="720px" top="25vh" :close-on-click-modal="false">
        <div class="content">
          <div class="content-row"><span>开锁时间：</span><span>{{failureObj.operationTime}}</span></div>
          <div class="content-row"><span>开锁状态：</span><span>{{failureObj.resultStr}}</span></div>
          <div class="content-row failureCause">
            <div>失败原因：</div>
            <div style="width:540px;">{{failureObj.failureCause}}</div>
          </div>
          <div class="content-row failureCause">
            <div>详细日志：</div>
            <div class="flowLog">{{failureObj.flowLog}}</div>
          </div>
        </div>
        <div class="dialog-footer">
          <el-button @click="closeFun()">关闭</el-button>
        </div>
      </el-dialog>
  
    </div>
</template>
  <script>
    export default {
      data() {
        let _this = this;
        return {
          opt: {
            search: [{
                key: "date",
                label: "起止日期",
                timeType: "yyyy-MM-dd HH:mm:ss",
                format: "yyyy-MM-dd",
                type: "daterange"
              },
              {
                key: "actionType",
                label: "开锁方式",
                type: "select",
                opt: {
                  list: [{
                  label: "手机蓝牙开锁",
                  value: "local_blue"
                },
                {
                  label: "W7人脸设备远程开锁",
                  value: "w7_remote"
                },
                {
                  label: "W8人脸设备远程开锁",
                  value: "w8_remote"
                },
                {
                  label: "W7人脸开锁",
                  value: "w7_local_face"
                },
                {
                  label: "W8人脸开锁",
                  value: "w8_local_face"
                },
                {
                  label: "W6门铃开锁",
                  value: "doorbell_remote"
                },
                {
                  label: "密码开锁",
                  value: "password_unlock"
                },
                {
                  label: "指纹开锁",
                  value: "fingerprint_unlock"
                },
                {
                  label: "小程序蓝牙开锁",
                  value: "local_mp"
                },
                {
                  label: "IC卡开锁",
                  value: "card_unlock"
                },
                {
                  label: "人脸开锁",
                  value: "face_unlock"
                },
                {
                  label: "扫码开锁",
                  value: "scan_qrcode_unlock"
                },
                {
                  label: "机械钥匙开锁",
                  value: "mechanical_unlock"
                },
                {
                  label: "其他蓝牙开锁",
                  value: "other_bt_unlock"
                },
              ],
                }
              }
            ],
            columns: [{
                label: "序号",
                key: "sequenceNumber",
                width: 80
              },
              {
                label: "开锁方式",
                key: "actionTypeStr"
              },
              {
                label: "开锁人",
                key: "keyName"
              },
              {
                label: "电话号码",
                key: "userMobile"
              },
              {
                label: "锁版本号",
                key: "version"
              },
              {
                label: "门锁电量",
                key: "batteryPowerStr"
              },
              {
                label: "开锁时间",
                key: "operationTime"
              },
              {
                label: "手机品牌/型号",
                key: "phoneInfo"
              },
              {
                label: "微信版本号",
                key: "wechatVersion"
              },
              {
                label: "客户端平台/操作系统/版本",
                key: "terraceInfo",
                width: 200,
              },
              {
                label: "开锁状态",
                key: "resultStr",
                type: "actionStatus",
                opt: {
                  ativeStatus: "开锁失败",
                  on(row, item) {
                    _this.dialogVisible = true;
                    _this.failureObj = {
                        operationTime: '',
                        resultStr: '',
                        failureCause: '',
                        flowLog: '',
                    }
                    _this.failureObj.operationTime = row.operationTime;
                    _this.failureObj.resultStr = row.resultStr;
                    _this.failureObj.failureCause = row.failureCause;
                    _this.failureObj.flowLog = row.flowLog || '--';
                  }
                }
              }
            ],
            exportButtons: [
            {
              type: "Export",
              url: this.EXport + "/homelock-service/api/v1/device/operation/export",
              listName: `云数智家锁_开锁日志_${moment(new Date()).format("YYYYMMDD")}`,
              dto: {
                deviceId: this.$route.params.deviceId,
              },
            }]
          },
          roomList: [],
          dialogVisible: false,
          failureObj: {
            operationTime: '',
            resultStr: '',
            failureCause: '',
            flowLog: '',
          }
        };
      },
      created() {
        console.log("personDoorOpen created!!");
        
      },
      activated() {
        console.log("personDoorOpen activated!!");
        this.userInfo = this.cache.getLS("userInfo");
        if(this.userInfo.roleType != '1') {//非超级管理员不展示导出
          this.opt.exportButtons = []
        }else {
          this.opt.exportButtons[0].dto = {
            deviceId: this.$route.params.deviceId,
          }
        }
      },
      mounted() {
  
      },
      methods: {
        onGet(opt) {
          let dto = {
            current: opt.skip,
            pageSize: opt.limit,
            deviceId: this.$route.params.deviceId,
            beginActionTime: opt.searchForm["date"] ? opt.searchForm["date"][0] : "",
            endActionTime: opt.searchForm["date"] ? opt.searchForm["date"][1] : "",
            actionType: opt.searchForm["actionType"] ? opt.searchForm["actionType"] : null
          };
          this.post("homelock-service/api/v1/device/operation/page", dto, {
            isUseResponse: true
          }).then(res => {
            for (let i = 0; i < res.data.data.length; i++) {
                let item = res.data.data[i];
                if(item.battery) {
                    if(item.battery < 30) {
                        item.batteryPowerStr = "低电" + "（" + item.battery + '%' + "）";
                    } else {
                        item.batteryPowerStr = "正常" + "（" + item.battery + '%' + "）";
                    }
                }
                item.sequenceNumber=opt.limit*(opt.skip-1)+i+1;
                if (item.actionType == 'local_blue') {
                  item.actionTypeStr = '手机蓝牙开锁';
                } else if (item.actionType == 'w7_remote') {
                  item.actionTypeStr = 'W7人脸设备远程开锁';
                }  else if (item.actionType == 'w8_remote') {
                  item.actionTypeStr = 'W8人脸设备远程开锁';
                } else if (item.actionType == 'w7_local_face') {
                  item.actionTypeStr = 'W7人脸开锁';
                }  else if (item.actionType == 'w8_local_face') {
                  item.actionTypeStr = 'W8人脸开锁';
                } else if (item.actionType == 'doorbell_remote') {
                  item.actionTypeStr = 'W6门铃开锁';
                } else if (item.actionType == 'password_unlock') {
                  item.actionTypeStr = '密码开锁';
                } else if (item.actionType == 'fingerprint_unlock') {
                  item.actionTypeStr = '指纹开锁';
                } else if (item.actionType == 'local_mp') {
                  item.actionTypeStr = '小程序蓝牙开锁';
                } else if (item.actionType == 'card_unlock') {
                  item.actionTypeStr = 'IC卡开锁';
                } else if (item.actionType == 'l2_public_unlock') {
                  item.actionTypeStr = 'L2蓝牙公钥开锁';
                } else if (item.actionType == 'face_unlock') {
                  item.actionTypeStr = '人脸开锁';
                } else if(item.actionType == 'scan_qrcode_unlock') {
                  item.actionTypeStr = '扫码开锁';
                } else if(item.actionType == 'mechanical_unlock') {
                  item.actionTypeStr = '机械钥匙开锁';
                } else if(item.actionType == 'other_bt_unlock') {
                  item.actionTypeStr = '其他蓝牙开锁';
                } else {
                  item.actionTypeStr = '手机蓝牙开锁';
                }
                item.version = item.version ? item.version : '--'
                item.resultStr = ["开锁成功", "开锁失败",'开锁超时','执行指令超时'][item.result];
                if(item.result !== 0) {
                  item.resultStr = item.failureCause ? "开锁失败" : "开锁失败 "
                }
              if(item.brand && item.model) {
                item.phoneInfo = item.brand + ' ' + item.model;
              }else {
                item.phoneInfo = '--';
              }
              if(item.platform && item.operSys) {
                item.terraceInfo = item.platform + ' ' + item.operSys;
              }else {
                item.terraceInfo = '--';
              }
              item.wechatVersion = item.wechatVersion ? item.wechatVersion : '--';
              
              if (item.result == 1) {
                item.failureFlag = 0; //开锁失败客点击查看失败原因
              } else if (item.result == 0) {
                item.failureFlag = 1;
              }
  
              item.date = this.format(item.operationTime);

              if(item.result == "1") {
                  item.batteryPower = '--';
              }else {
                  item.batteryPower = item.batteryPower + "%";
              }
              if (this.cache.getLS("userInfo")["roleType"] != '8') {
                item.unLockUserPhoneStr = item.unLockUserPhone ?
                  item.unLockUserPhone.substring(0, 3) +
                  "****" +
                  item.unLockUserPhone.substring(7, item.unLockUserPhone.length) :
                  "--";
              } else {
                item.unLockUserPhoneStr = item.unLockUserPhone;
              }
              if (item.result == "1") {
                item.bk = 2;
              } else {
                item.bk = 0;
              }
            }
            opt.cb(res.data);
          });
        },
        closeFun() {
          this.dialogVisible = false;
        }
      }
    };
  </script>
  <style lang="scss" scoped>
    .table-box {
      height: 620px;
    }
  
  
    .content {
      padding-left: 39px;
    }
  
    .content-row {
      padding-bottom: 24px;
    }
  
    .failureCause {
      display: flex;
    }
  
    .flowLog {
      display: flex;
      width: 540px;
      max-height: 200px;
      overflow: auto;
      margin-bottom: 10px;
    }
  
    .dialog-footer {
      text-align: center;
    }
  </style>