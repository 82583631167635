<template>
  <div class="personInfo">
    <div class="personInfo-lt">
      <div class="content-box" v-for="item in dataListLeft" :key="item.key">
        <div class="title">{{item.label}}</div><span class="content">{{form[item.key]}}{{item.value}}</span>
      </div>
    </div>
    <div class="personInfo-rt">
      <div class="content-box" v-for="item in dataListRight" :key="item.key">
        <div class="title">{{item.label}}</div><span class="content">{{form[item.key]}}{{item.value}}</span>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      let _this = this;
      return {
        dataListLeft: [{
            label: "门锁型号：",
            key: "model",
            isCol50: true,
          },
          {
            label: "MAC地址：",
            key: "deviceMac",
            isCol50: true,
          },
          {
            label: "锁名称/编号：",
            key: "deviceName",
            isCol50: true,
          },
          {
            label: "设备端软件版本：",
            key: "firmwareVersion",
            isCol50: true,
          },
          {
            label: "管理员（用户）：",
            key: "userName",
            isCol50: true,
          },
          {
            label: "管理员手机号码：",
            key: "userPhone",
            isCol50: true,
          },
          {
            label: "绑定时间：",
            key: "createTime",
            isCol50: true,
          },
        ],
        dataListRight: [{
            label: "最近一次开锁时间：",
            key: "lastActionTime",
            isCol50: true,
          },
          {
            label: "绑锁状态：",
            key: "bindStateStr",
            isCol50: true,
          },
          {
            label: "关联设备：",
            key: "relationName",
            isCol50: true,
          },
          {
            label: "关联设备型号：",
            key: "relationModel",
            isCol50: true,
          },
          {
            label: "关联设备MAC：",
            key: "relationMac",
            isCol50: true,
          },
          {
            label: "解绑人：",
            key: "unbindUserName",
            isCol50: true,
          },
          {
            label: "解绑人账号：",
            key: "unbindUserAccount",
            isCol50: true,
          },
          {
            label: "解绑时间：",
            key: "unbindTime",
            isCol50: true,
          }
        ],
        form: {}
      };
    },
    created() {
      console.log("personInfo created!!");
    },
    activated() {
      console.log("personInfo activated!!");
      this.get("homelock-service/api/v1/device/" + this.$route.params.deviceId).then(data => {
        data.bindStateStr = ["已绑定","已解绑"][data.bindState];
        data.userPhone = data.userPhone ? data.userPhone : '--';
        data.firmwareVersion = data.firmwareVersion ? data.firmwareVersion : '--';
        data.createTime = data.createTime ? data.createTime : '--';
        data.lastActionTime = data.lastActionTime ? data.lastActionTime : '--';
        if (data.gateway) {
          data.relationName = data.gateway.deviceName ? data.gateway.deviceName : '--'
          data.relationModel = data.gateway.model ? data.gateway.model : '--'
          data.relationMac = data.gateway.deviceMac ? data.gateway.deviceMac : '--'
        } else {
          data.relationName = '--'
          data.relationModel = '--'
          data.relationMac = '--'
        }
        if(data.bindState == 1){
          data.unbindUserName = data.unbindUserName ? data.unbindUserName : '--'
          data.unbindUserAccount = data.unbindUserAccount ? data.unbindUserAccount : '--'
          data.unbindTime = data.unbindTime ? data.unbindTime : '--'
        }
        this.$set(this, 'form', data);
        if (data.bindState == 1) {
          this.dataListRight = [{
            label: "最近一次开锁时间：",
            key: "lastActionTime",
            isCol50: true,
          },
          {
            label: "绑锁状态：",
            key: "bindStateStr",
            isCol50: true,
          },
          {
            label: "关联设备：",
            key: "relationName",
            isCol50: true,
          },
          {
            label: "关联设备型号：",
            key: "relationModel",
            isCol50: true,
          },
          {
            label: "关联设备MAC：",
            key: "relationMac",
            isCol50: true,
          },
          {
            label: "解绑人：",
            key: "unbindUserName",
            isCol50: true,
          },
          {
            label: "解绑人账号：",
            key: "unbindUserAccount",
            isCol50: true,
          },
          {
            label: "解绑时间：",
            key: "unbindTime",
            isCol50: true,
          }]
        } else {
          this.dataListRight = [{
            label: "最近一次开锁时间：",
            key: "lastActionTime",
            isCol50: true,
          },
          {
            label: "绑锁状态：",
            key: "bindStateStr",
            isCol50: true,
          },
          {
            label: "关联设备：",
            key: "relationName",
            isCol50: true,
          },
          {
            label: "关联设备型号：",
            key: "relationModel",
            isCol50: true,
          },
          {
            label: "关联设备MAC：",
            key: "relationMac",
            isCol50: true,
          }]
        }
      })
    },
    mounted() {

    },
    methods: {

    }
  };
</script>
<style lang="scss" scoped>
  .table-box {
    height: 620px;
  }

  .personInfo {
    margin: auto;
    width: 1200px;
    display: flex;
    flex-wrap: wrap;

    .personInfo-lt {
      width: 50%;
    }

    .personInfo-rt {
      width: 50%;
    }

    .content-box {
      margin: 20px 0;
      font-size: 15px;

      .title {
        display: inline-block;
        padding-right: 40px;
        text-align: right;
        width: 200px;
        color: #303133;
        // font-weight: 700;
      }


      .content {
        color: #666;
      }
    }

    .editBox {
      display: inline-block;
      margin: auto;

      .editTitle {
        display: inline-block;
        padding-right: 10px;
        text-align: right;
        width: 101px;
        color: #606266
      }

      .editContent {
        margin: 10px;
      }

      .el-textarea {
        width: 53%;
      }

      .el-input {
        width: 53%;
      }
    }

    .personInfo-check-title {
      padding-bottom: 10px;
      font-weight: 700;
    }

    .personInfo-check-text {
      padding-bottom: 10px;
      display: flex;

      span {
        padding-right: 46px;
      }
    }

    .table-box {
      height: 400px;
      overflow-y: auto;
    }
  }
</style>